.FormLine {
  display: flex;
  position: relative;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-left: 10px;
}
.TextInForm {
  margin-left: 50px;
  margin-bottom: 5px;
  width: 150px;
  color: rgb(169, 169, 169);
  font-weight: bold;
}
.OneMiddle {
  align-content: center;
  flex-direction: column;
}

/*Gestion du responsive*/

@media screen and (min-width: 795px) {
  .TwoItems {
    margin-right: 245px;
  }
  .OneMiddle {
    justify-content: center;
    flex-direction: row;
    position: relative;
  }
  .TextInForm {
    left: 70px;
    top: 20px;
    margin-left: 0px;
    position: absolute;
    margin-top: 5px;
  }
  .OneEnd {
    justify-content: flex-end;
    margin-right: 25px;
  }
}
@media screen and (max-width: 850px) {
  .OneEnd {
    margin-right: 0px;
  }
}
/* Question de gout
@media screen and (max-width: 795px) {
  .FormLine {
    justify-content: flex-start;
  }
}
*/
