.Hidden {
  display: none;
}

.DateForm {
  color: black;
  opacity: 0.9;
  z-index: 11;
  background-color: transparent;
  width: 225px;
}
div.react-datepicker-popper,
.react-datepicker__tab-loop {
  z-index: 100 !important;
}

.CalendarIcon {
  opacity: 0.5;
  position: absolute;
  width: 20px;
  right: 7px;
  top: 30px;
  z-index: 0;
}
