.HomeContainer {
  max-width: 1000vw;
  align-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.PageContent {
  position: relative;
  max-width: 800px;
  background-color: white;

  box-shadow: 0px 0px 5px 0px rgba(119, 119, 119, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(119, 119, 119, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(119, 119, 119, 0.75);
}

.PageContent {
  padding: 25px;
  margin-top: 25px;
}

.PageHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.TopRightMenu  {
  text-align: right;
}

.MenuContainer {
  margin-top: 20px;
  text-align: right;
  display: flex;
}

.IconMenu {
  width: 50px;
  height: 50px;
}

.DateMaj {
  text-align: right;
}
.SectionName {
  margin-top: 35px;
  text-align: left;
}

.SectionContent {
  margin-top: 20px;
}

.Menu {
  background-color: white;
  z-index: 10;
  position: absolute;
  top: 65px;
  right: 107px;
  margin-top: 10px;
  color: gray;
  width: 150px;
  text-align: left;
  border-radius: 10px;
  /*visibility: hidden;*/
}
@media screen and (max-width: 528px) {
  .Menu {
    top: 92px;
    right: 60px;
  }
}
@media screen and (max-width: 357px) {
  .Menu {
    top: 115px;
  }
}
.MenuItem {
  z-index: 1;
  padding: 10px;
  border: 1px solid gray;
}
.MenuItem:hover {
  background-color: lightgray;
}

.firstItem {
  border-bottom: 0px;
  border-radius: 10px 10px 0px 0px;
}
.middleItem  {
  border-radius: 0px 0px 0px 0px;
}

.lastItem {
  border-top: 0px;
  border-radius: 0px 0px 10px 10px;
}

.button {
  border: none;
  background-color: transparent;
}

a {
  text-decoration: none;
  color: gray;
}
h1,
h2 {
  color: #749732;
}
h2 {
  margin-top: 50px;
  margin-bottom: 50px;
}

#Depenses,
#Recettes {
  margin-bottom: 20px;
}

@keyframes example {
  from {
    background-color: #7497328a;
  }
  to {
    background-color: #749732;
  }
}
.formButton.disabledformButton {
  animation-name: example;
  animation-duration: 1s;

  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.DeleteSign {
  width: 15px;
  height: 15px;
  position: absolute;
  z-index: 5;
  left: -35px;
  top: 10px;
  filter: invert(100%) sepia(3%) saturate(1959%) hue-rotate(211deg)
    brightness(60%) contrast(65%);
}

td .DeleteSign {
  left: -23px;
  top: 15px;
}

@media screen and (max-width: 850px) {
  td .DeleteSign {
    left: -250px;
  }
}

@media screen and (max-width: 450px) {
  td .DeleteSign {
    left: -200px;
  }
}
