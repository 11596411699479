/*On réecris tout les styles des inputs utilisés dans la section général, on garde les mécaniques*/

td {
  background-color: transparent;
}

td .InputFormContainer {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0px;
  margin: 0px;
  padding: 0px;
}
table .InputForm {
  border: none;
  border-radius: 0px;
  background-color: transparent;
  font-weight: normal;
  font-size: 15px;
  padding: 0.5em;
}

table select {
  white-space: pre-line;
  padding: 0px;
  margin: 0px;
  width: 105px;
  font-weight: normal;
  font-size: 15px;
  padding-right: 15px;
}
table .SmallerinputList select {
  padding: 0px;
  margin: 0px;
  width: 45px;
  font-weight: normal;
  font-size: 15px;
}
table .DateFormContainer,
table .Hidden,
table .DateForm {
  width: 100px;
}
table .CalendarIcon {
  right: 15px;
  top: 12px;
}

table .ChevronList {
  right: 0px;
  top: 12px;
}

/* test tableau*/

table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 10px;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.SectionTableau table tr th {
  background-color: white;
  color: gray;
}
@media screen and (min-width: 850px) {
  .SectionTableau table tr .Date input {
    font-size: 13px;
  }
}

table th,
table td {
  border: 1px solid gray;
  border-collapse: collapse;

  text-align: center;
}

table th {
  padding: 0.5em;
  font-size: 10px;
}
table .ErrorTable {
  z-index: 10;
  border-bottom: 2px solid rgba(255, 0, 0, 0.7);
}
table .ErrorForm {
  display: none;
}
/*Small Screen*/
@media screen and (max-width: 850px) {
  .SectionTableau {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  table .InputFormContainer {
    align-items: flex-end;
    display: flex;
    width: 190px;
    margin-left: 10px;
  }
  table .InputForm {
    width: 190px;
  }
  table select,
  table .SmallerinputList select {
    width: 190px;
  }
  table .DateFormContainer {
    display: flex;
    align-items: flex-end;
    width: 190px;
  }
  table .DateForm {
    width: 190px;
  }
  table .CalendarIcon {
    right: 5px;
    top: 12px;
  }
  table .ChevronList {
    right: 3px;
    top: 16px;
  }

  table {
    border: 0px;
    width: 400px;
  }

  table caption {
    font-size: 10px;
  }

  table thead {
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    display: block;
  }

  table td {
    border-bottom: none;
    color: gray;
    font-size: 15px;
    text-align: left;
    display: flex;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    display: flex;
    align-items: center;
    content: attr(data-label);
    font-weight: bold;
    width: 200px;
    margin-left: 10px;
  }

  table td:last-child {
    border: 1px solid gray;
  }
  table .ErrorTable {
    z-index: 10;
    border-bottom: none;
  }

  table .ErrorForm {
    top: 1px;
    position: relative;
    display: initial;
    width: 386px;
  }
}

@media screen and (max-width: 450px) {
  table {
    width: auto;
    padding: 0px;
    margin: 0px;
  }
  table .InputFormContainer,
  table .InputForm,
  table input,
  table .ErrorForm,
  .InputFormList {
    width: 100px;
  }
  table td::before {
    width: 150px;
  }
}

table tr {
  margin-bottom: 30px;
}
