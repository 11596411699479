h5 {
  font-size: 17px;
  text-align: left;
  padding-left: 5px;
}

.formButton {
  margin-top: 20px;
  appearance: none;
  border: 1px solid rgb(132, 162, 57);
  border-radius: 3px;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
  min-width: 170px;
  background-color: white;
  max-width: 200px;
  color: black;
}

.formButton:hover {
  background-color: rgba(132, 162, 57, 0.575);
}

@media screen and (max-width: 794px) {
  .FourItemsPerLine {
    width: 60%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .FourItemsPerLine .InputFormContainer {
    margin-bottom: 10px;
  }
  .InputFormContainer {
    margin-left: 20px;
  }
  .TermePaiementContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
}

.ErrorTerme {
  color: red;
  text-align: left;
  padding-left: 5px;
  margin-bottom: 1rem;

}
