.Header {
  min-height: 100px;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-flow: row wrap;
  background-color: white;
  box-shadow: 0px 0px 5px 0px rgba(119, 119, 119, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(119, 119, 119, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(119, 119, 119, 0.75);
}

.TabsGroup {
  flex-grow: 2;
  min-height: 100px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-flow: row wrap;
}

.Tabname {
  text-decoration: none;
  color: black !important;
}
.Tab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: transparent;
}

.Tab.active,
.Tab:hover {
  padding-top: 5px;
  padding-bottom: 5px;

  border-bottom: 1px solid #749732;
}
.LogoButton {
  border: none;
  background-color: transparent;
}
.SubTab.Tab:hover {
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgb(222, 128, 117);
}
.LogoTab {
  filter: invert(51%) sepia(67%) saturate(381%) hue-rotate(39deg)
    brightness(93%) contrast(93%);
  flex-grow: 1;
  width: 25px;
  margin-right: 10px;
}
.SubTab .LogoTab {
  filter: invert(58%) sepia(57%) saturate(2043%) hue-rotate(322deg)
    brightness(96%) contrast(78%);
}

.Tab.logout {
  margin-right: 15px;
}

.Logo {
  margin: 15px;
  width: 75px;
  height: 75px;
}

.SubTab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: transparent;
  margin-top: 1rem;
}

.SubTab:hover {
  cursor: pointer;
}
