.InputFormContainer {
  width: 225px;
  flex-shrink: 3;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  border-radius: 5px;
  margin-right: 30px;
  margin-bottom: 30px;
  position: relative;
}

.InputForm {
  font-weight: bold;
  width: 215px;
  height: 30px;
  width: 100%;
  padding-left: 10px;
  border-radius: 5px;
  border: 1px solid gray;
  z-index: 1;
}
.LongLabel {
  height: 50px;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
.LongLabel::placeholder {
  top: 10px;
  position: absolute;
  align-content: center;
}
::-webkit-input-placeholder {
  white-space: normal;
  font-size: 16px;
}
:-moz-placeholder {
  white-space: normal;
  font-size: 16px;
}
::placeholder {
  font-size: 16px;
  white-space: normal;
}
.FourItemsPerLine .InputFormContainer,
.FourItemsPerLine .InputForm {
  width: 150px;
}

.PercentSign {
  position: absolute;
  z-index: 2;
  width: 15px;
  height: 15px;
  filter: invert(100%) sepia(3%) saturate(1959%) hue-rotate(211deg)
    brightness(60%) contrast(65%);
  right: 7px;
  top: 30px;
}

.ErrorForm {
  height: fit-content;
  width: fit-content;
  color: red;
  bottom: -20px;
}

.InputForm {
}


.LabelForm {
  text-align: start;
}
