.PageContentBudget {
  width: 90%;
}
.BoutonActionBudgets {
  display: flex;
  position: relative;
}
.PageContentBudget .LigneFicheContainer {
  justify-content: space-between;
  width: 100%;
}

.ActionMenu {
  position: absolute;
  display: flex;
  flex-direction: column;

  width: 110px;
  z-index: 1;
  right: 52px;
}

.ActionMenu .buttonAction {
  border: 2px solid #749732;
  background-color: white;
  font-weight: bold;
  color: gray;
  height: 40px;
  text-align: center;
  display: grid;
  place-items: center;
}
.ActionMenu .uno {
  background-color: white;
  border-bottom: transparent;
}
.dos {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: white;
}
.buttonAction:hover {
  cursor: pointer;
  color: lightgray;
}
@media screen and (max-width: 513px) {
  .ActionMenu {
    right: 11px;
    top: 40px;
  }

  .PageContentBudget {
    width: auto;
  }
  .PageContentBudget .LigneFicheContainer {
    flex-direction: column;
    height: min-content;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  .BoutonActionBudgets {
    justify-content: space-around;
  }
  .PageContentBudget .LigneFicheOpenButton,
  .LigneFicheActionButton {
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 388px) {
  .ActionMenu {
    right: 11px;
    top: 100px;
  }
  .BoutonActionBudgets {
    flex-direction: column;
  }

  .PageContentBudget {
    width: 80%;
  }
}
