.LoginForm {
  width: 300px;
  height: 400px;
  margin-left: 50vw;
  margin-top: 50vh;
  transform: translate(-50%, -50%);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0px 0px 10px 0px rgba(119, 119, 119, 0.75);
  -moz-box-shadow: 0px 0px 10px 0px rgba(119, 119, 119, 0.75);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(119, 119, 119, 0.75);

  background-color: white;
}

.Logo {
  width: 250px;
  height: 250px;
}
input {
  font-size: large;
  border: none;
  outline: none;
  width: 70%;
}
.Icon {
  filter: invert(22%) sepia(52%) saturate(2127%) hue-rotate(196deg)
    brightness(92%) contrast(88%);
  height: 25px;
  min-width: 25px;
  margin-left: 10px;
  margin-right: 10px;
}
.Input {
  height: 40px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: white;
  border-radius: 5px;
  border: 1px solid gray;
  width: 80%;
}

.Button {
  height: 40px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #1c4e91;
  border-radius: 5px;
  min-width: 40%;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
}

.Button:disabled,
.Button[disabled],
.Button:hover {
  background-color: lightblue;
}

.Buttontext {
  padding-left: 10px;
  color: white;
}

.IconQuit {
  width: 20px;
  height: 25px;
  filter: invert(100%) sepia(9%) saturate(223%) hue-rotate(306deg)
    brightness(119%) contrast(100%);
}
