.SimplePopUp {
  z-index: 40;
  top: 0px;
  padding: 20px;
  background-color: white;
  transform: translateY(-50%) translateX(50%);
  right: 50%;
  min-height: 100px;
  min-width: 200px;
  border-radius: 15px;
  border: 2px solid #749732;
  box-shadow: 0px 0px 15px 0px rgba(119, 119, 119, 0.75);
  -moz-box-shadow: 0px 0px 15px 0px rgba(119, 119, 119, 0.75);
  -webkit-box-shadow: 0px 0px 15px 0px rgba(119, 119, 119, 0.75);
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: column;
  position: absolute;
}

@media screen and (max-width: 270px) {
  .SimplePopUp {
    transform: translateY(-100%);
    min-width: 0px;
  }
}
