.LigneFicheContainer {
  border: 1px solid gray;
  display: flex;
  flex-direction: row;
  height: 80px;
  align-items: center;
  margin-bottom: 30px;
}
.LigneFicheData,
.LigneFicheOpenButton,
.LigneFicheActionButton {
  width: 90px;
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-weight: bold;
  color: rgba(129, 129, 129, 0.7);
}
.LigneFicheOpenButton,
.LigneFicheActionButton {
  border: 1px solid #749732;
  padding: 0px;
  height: 40px;
  width: 110px;
  margin-left: 20px;
  border-radius: 5px;
  cursor: pointer;
}
.LigneFicheActionButton {
  margin-right: 50px;
}
.ChevronAction {
  width: 20px;
  filter: invert(100%) sepia(3%) saturate(1959%) hue-rotate(211deg)
    brightness(60%) contrast(65%);
}

.LigneFicheOpenButton:hover,
.LigneFicheActionButton:hover {
  background-color: #749732;
  color: white;
  filter: brightness(120%);
}

.SectionLigneFiche {
  display: flex;
}

.SearchbarContainer {
  height: 35px;
  margin-top: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.SearchbarSubContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 530px;
  width: 122px;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #749732;
  background-color: white;
  position: relative;
}
.SearchBar {
  background-color: transparent;
  z-index: 2;
  height: 100%;
  text-align: end;
  font-weight: bold;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  padding: 5px;
  border-radius: 5px;
}
.SearchIcon {
  position: absolute;
  width: 20px;
  left: 5px;
  filter: invert(100%) sepia(3%) saturate(1959%) hue-rotate(211deg)
    brightness(80%) contrast(65%);
}

@media screen and (max-width: 870px) {
  .SearchbarSubContainer {
    margin-left: 0px;
  }
  .Fiche.ActionMenu {
    right: 22px;
    top: 41px;
  }
  .PageFiche .LigneFicheData {
    margin: 0px;
    padding-top: 10px;
    width: fit-content;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-weight: bold;
    color: rgba(129, 129, 129, 0.7);
  }
  .PageFiche .LigneFicheContainer {
    flex-direction: column;
    align-items: center;
    height: fit-content;
    padding-bottom: 20px;
  }
  .PageFiche .LigneFicheOpenButton,
  .PageFiche .LigneFicheActionButton {
    margin-right: 20px;
  }
}

@media screen and (max-width: 375px) {
  .LigneFicheContainer {
    width: 250px;
  }
  .LigneFicheData,
  .LigneFicheOpenButton {
    flex-direction: column;
  }
}
.BoutonsFiches {
  position: relative;
  width: 100%;
}

.FilterFiches {
  text-align: center;
  min-width: 100px;
  padding: 5px;
  border: 1px solid #749732;
  background-color: white;
}
