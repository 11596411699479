.PopUpContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  display: grid;
  background-color: rgba(119, 119, 119, 0.75);
  z-index: 2;
  place-items: center;
}

.PopUp {
  padding: 20px;
  transform: translateY(-200%);
  background-color: white;
  min-height: 100px;
  min-width: 200px;
  border-radius: 15px;
  border: 2px solid #749732;
  box-shadow: 0px 0px 15px 0px rgba(119, 119, 119, 0.75);
  -moz-box-shadow: 0px 0px 15px 0px rgba(119, 119, 119, 0.75);
  -webkit-box-shadow: 0px 0px 15px 0px rgba(119, 119, 119, 0.75);
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: column;
}

.ButtonPopUp {
  display: flex;
  justify-content: space-evenly;
}

.ButtonPopUp .LigneFicheOpenButton {
  margin: 0px;
}
.ButtonPopUp .ButtonCancelPopUp {
  border: none;
}
@media screen and (max-width: 270px) {
  .PopUp {
    transform: translateY(-100%);
    min-width: 0px;
  }

  .ButtonPopUp {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    margin-top: 30px;
    align-items: center;
  }
}
