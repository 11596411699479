select {
  /* Reset */
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  cursor: pointer;
  z-index: 7 !important;
  width: 230px;
  height: 34px;
  padding-left: 0px;
  background-color: transparent;
  opacity: 0.8;
  font-weight: bold;
  margin-bottom: 100px !important;
  /* Personalize

  color: gray;
  opacity: 0.8;
  width: 235px;
  height: 34px;
  padding-left: 10px;
  border-radius: 5px;
  border: 1px solid gray;
  z-index: 10;*/
}

.ChevronList {
  position: absolute;
  width: 20px;
  filter: invert(100%) sepia(3%) saturate(1959%) hue-rotate(211deg)
    brightness(60%) contrast(65%);
  right: 7px;
  top: 30px;
}

.InputFormListPlaceHolder {
  font-size: 15px;
  position: absolute;
  left: 5px;
  top: 30px;
  font-weight: bold;
  color: gray;
  opacity: 0.7;
  z-index: 1;
}
.InputFormContainer {
  position: relative;
}

.invisible {
  display: none;
}
