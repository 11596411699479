.RelicatsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

h4 {
  margin: 0px;
  color: gray;
}

.Relicats {
  color: gray;

  flex-shrink: 12;
  margin-bottom: 20px;
}

.TableContainer {
  position: relative;
}
